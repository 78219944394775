<template>
  <div>
    <div
      v-if="isEmpty == 0"
      class="text-center mt-5"
    >
      <h1>
        No engineers are available in this branch yet.
      </h1>
    </div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="seriesData"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    dataChart: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    seriesData() {
      return this.dataChart.source
    },
    isEmpty() {
      return this.dataChart?.engineers_name?.length || 0
    },
    labelsData() {
      return this.dataChart.engineers_name
    },
    chartColors() {
      // Define a base set of colors
      const baseColors = [
        '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF',
        '#33FFF3', '#FFC300', '#C70039', '#900C3F', '#581845',
      ]

      // Expand colors if needed
      const requiredColors = this.seriesData?.length || 0
      return Array.from({ length: requiredColors }, (_, i) => baseColors[i % baseColors.length])
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 750,
          stacked: true,
        },
        colors: this.chartColors,
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: this.labelsData,
          labels: {
            rotateAlways: true,
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50,
        },

      }
    },
  },

}
</script>
