<template>
  <b-row class="d-flex justify-content-center align-items-center m-0">
    <b-col
      v-for="(item, index) in dataTable"
      :key="index"
      md="2"
      class="w-100 d-flex align-items-center justify-content-center mx-1 my-1"
    >
      <b-row :class="`box box${index + 1} `">
        <b-col
          cols="12"
        >
          <!-- {{ item }} -->
          <b-row>
            <b-col
              cols="4"
              class="align-content-center"
            >
              <h2 class="text-light text-uppercase">
                {{ item.source }}
              </h2>
            </b-col>
            <b-col cols="8">
              <ChartLine :monthly-requests="item.months" />
            </b-col>

          </b-row>
          <h5 class="text-center text-light">
            <span>Number of Requests: </span>  {{ item.total_requests }}
          </h5>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>
import ChartLine from './ChartLine.vue'

export default {
  components: {
    ChartLine,
  },
  props: {
    dataTable: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style scoped>
.box {
  color: white;
  text-shadow: 0 1px 1px 1px #666;
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  border-radius: 5px;
  padding: 30px 0px;
  height: fit-content;
}
.box1 {
  background-image: linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%);
}
.box2 {
  background-image: linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%);

}
.box3 {
  background-image: linear-gradient( 135deg, #c793aa 10%, #8c5a78 100%);

}
.box4 {
  background-image: linear-gradient( 135deg, #f7c486 10%,  #de7e23 100%);
}
.box5 {
  background-image: linear-gradient( 135deg,   #99ccff 10%, #4d1694 100%);
}
.box6 {
  background-image: linear-gradient( 135deg, #EE9AE5 10%, #6e1fd4 100%);
}
 .box7 {
  background-image: linear-gradient( 135deg, #a3f7c6 10%, #1b897c 100%);
}
 @media screen and (max-width: 431px) {
  .box {
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
  margin: 5px 30px;
}
 }
</style>
