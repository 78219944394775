<template>
  <div class="control-home">
    <b-card class="bg-gradient-home">
      <!-- style="height: 80vh" -->
      <b-row
        class="d-flex align-content-center justify-content-center"
      >
        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <Logo />
        </b-col>
        <b-col
          cols="12"
          md="7"
          class="d-flex justify-content-center py-1"
        >
          <LogoText class="w-100" />
        </b-col>

      </b-row>
    </b-card>
    <b-row class="">
      <span
        v-if="roleAdmin === 'super-manager'"
        class="d-flex flex-wrap"
      >
        <h1
          class="header-branches px-2 py-2 w-100 text-center"
        >
          Our Branches
        </h1>
        <b-row class="d-flex flex-wrap justify-content-center">
          <b-col
            v-for="(data, index) in branches"
            :key="index"
            cols="6"
            md="1"
            class="d-flex flex-column justify-content-center align-items-center"
            :data-aos="index % 2 ? 'fade-left' : 'fade-right'"
            data-aos-easing="linear"
            data-aos-duration="400"
          >
            <img
              :src="data.img"
              class="w-100 h-100"
            >
            <h6 class="text-nowrap text-primary">
              {{ data.name }}
            </h6>
          </b-col>
        </b-row>
      </span>

      <b-col
        v-if="roleAdmin !== 'super-manager'"
        cols="12"
        class="py-4"
      >
        <div
          v-if="!loader"
          style="width: 100%;"
        >
          <SparkBoxes :data-table="dataTable" />
        </div>

        <div
          v-else
          class="text-center"
        >
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem; margin-top: 2rem;"
            label="Large Spinner"
          />
        </div>
      </b-col>

    </b-row>

    <b-row v-if="roleAdmin !== 'engineer-allocation' && roleAdmin !== 'engineer'">
      <span
        v-if="roleAdmin !== 'super-manager'"
        class="d-flex flex-wrap"
      >
        <h1
          class="header-branches px-2 py-2 w-100 text-center"
        >
          Our Branches
        </h1>
        <b-row class="d-flex flex-wrap justify-content-center">
          <b-col
            v-for="(data, index) in branches"
            :key="index"
            cols="6"
            md="1"
            class="d-flex flex-column justify-content-center align-items-center cursor-pointer"
            :class="data.isActive ? 'is-active' : ''"
            data-aos-easing="linear"
            data-aos-duration="400"
            @click="changeBranch(data)"
          >
            <img
              :src="data.img"
              class="w-100 h-100"
            >
            <h6 class="text-nowrap text-primary">
              {{ data.name }}
            </h6>
          </b-col>
        </b-row>
      </span>

      <b-col
        v-if="roleAdmin !== 'super-manager'"
        cols="12"
      >
        <ChartTable :data-chart="dataChart" />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import axios from 'axios'
import AOS from 'aos'
import decryptData from '@/store/auth/decryptData'
import LogoText from './components/LogoText.vue'
import Logo from './components/Logo.vue'
import SparkBoxes from './components/SparkBoxes.vue'
import ChartTable from './components/ChartTable.vue'

export default {
  components: {
    LogoText,
    Logo,
    SparkBoxes,
    ChartTable,
  },
  data() {
    return {
      dataTable: [],
      dataOffice: [],
      dataChart: {},
      loader: true,
      office_id: 1,
      roleAdmin: localStorage.getItem('user_role'),
      /* eslint-disable */
      branches: [
        { id: 5,isActive: false, name: ' Emirates', img: require('@/assets/images/branchs/Emirates.png') },
        { id: 4,isActive: false, name: '  Saudi Arabia', img: require('@/assets/images/branchs/Saudi.png') },
        { id: 1,isActive: true, name: ' Egypt', img: require('@/assets/images/branchs/Egypt.png') },
        { id: 2,isActive: false, name: ' Jordan', img: require('@/assets/images/branchs/Jordan.png') },
        { id: 3,isActive: false, name: ' Turkey', img: require('@/assets/images/branchs/Turkey.png') },
        { id: 6,isActive: false, name: ' Pakistan', img: require('@/assets/images/branchs/Pakistan.png') },
        { id: 7,isActive: false, name: ' India', img: require('@/assets/images/branchs/India.png') },
        { id: 8,isActive: false, name: ' China', img: require('@/assets/images/branchs/China.png') },
        { id: 9,isActive: false, name: ' Russia', img: require('@/assets/images/branchs/Russia.png') },
        { id: 10,isActive: false, name: ' Philippines', img: require('@/assets/images/branchs/Philippines.png') },
        { id: 13,isActive: false, name: ' Qatar', img: require('@/assets/images/branchs/Qatar.png') },
      ],
    
    }
  },
  mounted() {
    if (this.roleAdmin !== 'super-manager') {
      AOS.init({ once: false })
      this.getAnalysis()
      this.getCharts()
    }
  },
  watch: {
    office_id() {
      this.getCharts(); // Call getCharts whenever office_id changes
    }
  },
  methods: {
    changeBranch(selectedBranch) {
    this.branches.forEach(branch => {
      branch.isActive = branch.id === selectedBranch.id;
    });
    this.office_id = selectedBranch.id
  },
    getAnalysis() {
      axios.get('/analysis?summary=true').then(res => {
        const data = decryptData(res.data.payload).data?.analysis
        this.dataTable = data
        this.loader = false
      }).catch(error => {
        if (error) {
          this.loader = false
        }
      }).finally(() => {
        this.loader = false
      })
    },
    getCharts() {
      axios.get(`/load-analysis?office_id=${this.office_id}`).then(res => {
        const data = decryptData(res.data.payload).data?.analysis
        this.dataChart = data
        this.loader = false
      }).catch(error => {
        if (error) {
          this.loader = false
        }
      }).finally(() => {
        this.loader = false
      })
    },

  },
}
</script>

<style scoped>
.header-branches{
  font-family: "Irish Grover", cursive;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  text-decoration: underline;
  color: #090088;
}
.control-home{
  overflow-x: hidden;
}
.bg-gradient-home{
  /* background: linear-gradient( 135deg, #deedf7 10%, #b8d8f1 100%); */
  /* background: linear-gradient( 135deg, #ABDCFF 10%, #c0c9d0 100%); */
  background: linear-gradient( 135deg, #5bbbff 0%,  #e5f4ff  50% , #5bbbff  100%);

}
.is-active img{
  transform: translateY(-30px);
}
.is-active h6{
  /* color: aliceblue !important; */
  font-weight: bolder;
  padding: 5px 20px;
  border: #090088 1px solid;
  border-radius: 5px;
}
</style>
