<template>
  <div>
    <apexchart
      type="line"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    monthlyRequests: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    series() {
      return [{
        data: this.monthlyRequests.map(item => item.total_requests),
      }]
    },
    chartOptions() {
      return {
        chart: {
          height: 300,
          type: 'line',
          width: '100%',
          background: 'transparent',
          zoom: { enabled: false },
          pan: { enabled: false },
          animations: { enabled: false },
          toolbar: {
            show: false, // Hide the toolbar
          },
        },
        colors: ['#FFFFFF'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          range: 12,
          categories: [],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: (seriesName, { dataPointIndex }) => {
                const months = this.monthlyRequests.map(item => item.month)
                const month = months[dataPointIndex]
                return `${month}:`
              },
            },
          },
        },
      }
    },
  },
}
</script>
